import type { SlDialog } from '@shoelace-style/shoelace';

/**
 * Create listeners for Shoelace dialog open and close buttons.
 * Elements with the `data-sl-dialog-open` attribute will open the dialog
 * with the corresponding selector when clicked.
 * Elements with the `data-sl-dialog-close` attribute will close the dialog
 * with the corresponding selector when clicked.
 * @param element We look for dialog open and close buttons within this element.
 *
 * https://shoelace.style/components/dialog/
 */
function createDialogListeners(element: Document | HTMLElement) {
  element.querySelectorAll('[data-sl-dialog-open]').forEach((button) => {
    const dialogSelector = button.getAttribute('data-sl-dialog-open');
    if (!dialogSelector) {
      return;
    }
    const dialog = document.querySelector<SlDialog>(dialogSelector);
    if (dialog) {
      button.addEventListener('click', () => dialog.show());
    }
  });

  element.querySelectorAll('[data-sl-dialog-close]').forEach((button) => {
    const dialogSelector = button.getAttribute('data-sl-dialog-close');
    if (!dialogSelector) {
      return;
    }
    const dialog = document.querySelector<SlDialog>(dialogSelector);
    if (dialog) {
      button.addEventListener('click', () => dialog.hide());
    }
  });
}

export default function initShoelaceListeners() {
  // Add dialog listeners to the document and for new elements loaded via htmx
  createDialogListeners(document);
  document.addEventListener('htmx:load', ((
    event: CustomEvent<{ elt: HTMLElement }>
  ) => {
    createDialogListeners(event.detail.elt);
  }) as EventListener);
}
